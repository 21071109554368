import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import {
  memo,
  useCallback,
  useState,
  useMemo,
  useEffect,
  useRef,
  useContext,
} from "react";
import { useLocation, useHistory } from "react-router-dom";

import SearchAutocomplete from "components/Search/SearchAutocomplete";
import SearchFieldContainer from "containers/SearchFieldContainer";
import RequestContext from "pages/RequestContext";

import formTokens from "../../styles/tokens/tokens-form";
import TopSearchRecentSearchesItem from "./TopSearchRecentSearchesItem";

import searchActions from "actions/search";
import {
  TOP_SEARCH_PLACEHOLDER,
  SEARCH_PLACEHOLDER_SHORT,
} from "constants/base";
import { selectRecentSearchHistory } from "selectors/search";
import isMacOs, { isMacOsByUserAgent } from "utils/isMacOs";
import { getURLSearchTerm } from "utils/search";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useClearSearchResults from "hooks/useClearSearchResults";
import { useLoggedIn } from "hooks/useLoggedInUser";
import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes, { createBreakpoint } from "styles/ScreenSizes";

const analyticsVariables = {
  componentContext: "TopSearchContainer",
};

const searchresultStyles = {
  autocompleteOuter: {
    [createBreakpoint({ min: 1400 })]: {
      marginLeft: "-1.2rem",
    },
  },
};

const searchFieldStyles = {
  container: {
    zIndex: 1,
    transition: "none",
    [ScreenSizes.lgAndAbove]: {
      transition: "none",
      maxWidth: "47rem",
      minWidth: "17rem",
    },
    [ScreenSizes.xlAndAbove]: {
      minWidth: "24.375rem",
    },
    [createBreakpoint({ min: 1400 })]: {
      marginLeft: "-1.2rem",
    },
  },
  search: {
    [ScreenSizes.lgAndAbove]: {
      borderRadius: 25,
      background: formTokens.field.color.background.subtle.onImpact.default,
      border: `1px solid ${formTokens.field.color.border.subtle.onImpact.default}`,
    },
  },
  searchFocus: {
    paddingRight: "0.625rem",
    [ScreenSizes.lgAndAbove]: {
      paddingRight: "1.125rem",
      borderRadius: 25,
      background: formTokens.field.color.background.subtle.default,
      border: `1px solid ${formTokens.field.color.border.subtle.default}`,
      color: formTokens.field.color.text.subtle.default,
    },
  },
  input: {
    ...gStyles.fontBold,
    fontSize: "0.875rem",
    "::placeholder": {
      color: formTokens.field.placeholder.color.subtle.onImpact.default, // "var(--color-neutral-d8)",
      // opacity: 0.8,
      ...gStyles.fontRegularItalic,
    },
    [ScreenSizes.mdAndBelow]: {
      color: colours.bodyText,
    },
  },
  filterContainer: {
    minWidth: "7.92rem",
  },
  icon: {
    color: colours.bodyText,
    width: "unset",
    height: "unset",
    fontSize: "0.875rem",
    marginRight: "0.5rem",
    padding: 0,
    [ScreenSizes.lgAndAbove]: {
      fontSize: "0.875rem",
      margin: "1rem",
      marginRight: ".5rem",
    },
  },
  iconFocussed: {
    [ScreenSizes.lgAndAbove]: {
      color: "var(--color-neutral-d8)",
    },
  },
  iconHovered: {
    [ScreenSizes.lgAndAbove]: {
      color: "var(--color-neutral-d8)",
    },
  },
  FloatingStles: {
    color: colours.bodyText,
    "::placeholder": {
      color: colours.newDarkGrey,
      opacity: 0.8,
      fontSize: "1rem",
      ...gStyles.avalonLightItalic,
    },
  },
};

const baseStyles = {
  shortcutContainer: {
    display: "none",

    [ScreenSizes.lgAndAbove]: {
      display: "grid",
      gridTemplateColumns: "repeat(3, max-content)",
      gap: "0.2rem",
      color: colours.bodyText,
      alignItems: "center",
      opacity: 0.7,
    },
  },
  shortcut: {
    background: formTokens.field.shortcut.color.background.onImpact.default,
    padding: "0.4rem 0.5rem",
    fontWeight: "bolder",
    borderRadius: "0.3rem",
    color: formTokens.field.shortcut.color.text.onImpact.default,
    fontSize: "0.7rem",
    marginRight: ".6rem",
  },
  lightShortcut: {
    background: colours.newLightGrey,
    border: `1px solid ${colours.borderGrey}`,
    color: colours.bodyText,
  },
  searchTip: {
    color: colours.bodyText,
    fontWeight: "bolder",
    fontSize: "0.6rem",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0.47rem 0.5rem",
    background: "transparent",
    border: `1px solid ${colours.borderGrey}`,
    borderRadius: "0.313rem",
    cursor: "pointer",
    height: "1.5rem",
  },
  searchTipInner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.313rem",
    pointerEvents: "none",
  },
  lightSearchTip: {
    color: colours.white,
  },
  clearButton: {
    background: colours.outsideBorder,
    padding: "0.375rem 0.563rem",
    margin: "0 0.625rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    border: "none",
    width: "1.5rem",
    height: "1.5rem",
    borderRadius: "50%",
  },
  exitIcon: {
    pointerEvents: "none",
    margin: 0,
    color: colours.header,
    fontSize: "0.8rem",
    [ScreenSizes.lgAndAbove]: {
      margin: 0,
      color: colours.header,
      fontSize: "0.8rem",
    },
  },

  container: {
    position: "relative",
  },
  resultsContainer: {
    color: colours.bodyText,
    background: colours.white,
    width: "100%",
    position: "absolute",
    boxShadow: "0px 0px 11.5px 0 rgba(0, 0, 0, 0.25) ",
    borderRadius: 25,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTop: "solid 0.5px rgba(0, 0, 0, 0.1)",
    top: "100%",
    left: 0,
    padding: "0.5rem 0",
    overflow: "hidden",

    [createBreakpoint({ min: 1400 })]: {
      marginLeft: "-1.2rem",
    },
  },
};

const DEFAULT_SEARCH_LINK = "/search/all/q/";

const TopSearchContainer = (props) => {
  const {
    className,
    mobile: passedMobile,
    onCancelSearch,
    onFocus,
    onSearchChange,
    placeholder: passedPlaceholder,
    placeholderSmall: passedPlaceholderSmall,
    searchAutocompleteStyles,
    searchHref = DEFAULT_SEARCH_LINK,
    searchKey,
    showSearch,
    showTip,
    switchToWhiteStyles,
    types,
  } = props;

  const { isWindowSizeOrLess } = useWindowSize();
  const mobile =
    passedMobile !== undefined ? passedMobile : isWindowSizeOrLess("medium");

  const location = useLocation();
  const { pathname } = location;

  const [lastTerm, setLastTerm] = useState(null);
  const urlsearchTerm =
    pathname && pathname.includes("/search/")
      ? pathname?.split("/").pop()
      : null;
  const term = getURLSearchTerm(urlsearchTerm);
  const [searchTerm, setSearchTerm] = useState(term || "");

  const searchElement = useRef(null);
  const containerRef = useRef(null);

  const history = useHistory();

  const { server, userAgent } = useContext(RequestContext);

  const { styles, css } = useStyles(baseStyles, props);

  const { loadRecentSearchHistory, saveRecentSearch } = useActionCreators({
    loadRecentSearchHistory: searchActions.loadRecentSearchHistory,
    saveRecentSearch: searchActions.saveRecentSearch,
  });

  const isLoggedIn = useLoggedIn();

  useEffect(() => {
    isLoggedIn && loadRecentSearchHistory();
  }, [loadRecentSearchHistory, isLoggedIn]);

  const recentSearches = useReduxState(
    (state) => selectRecentSearchHistory(state),
    []
  );

  const finalSearchStyles = useMemo(() => {
    return {
      ...searchFieldStyles,
      ...searchAutocompleteStyles,
      ...(recentSearches?.size > 0 && isLoggedIn && searchTerm === ""
        ? {
            searchFocusBorder: {
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              [ScreenSizes.lgAndAbove]: {
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              },
            },
          }
        : {}),
    };
  }, [searchAutocompleteStyles, recentSearches?.size, isLoggedIn, searchTerm]);

  const placeholder = useMemo(() => {
    if (mobile && (passedPlaceholderSmall || !passedPlaceholder)) {
      return passedPlaceholderSmall || SEARCH_PLACEHOLDER_SHORT;
    }

    return passedPlaceholder || TOP_SEARCH_PLACEHOLDER;
  }, [mobile, passedPlaceholder, passedPlaceholderSmall]);

  const handleSearchChange = useCallback(
    () => (value) => {
      setLastTerm(value);
      setSearchTerm(value);
    },
    []
  );

  const handleKeyPress = useCallback((event) => {
    if (
      (event.ctrlKey && event.key === "k") ||
      (event.metaKey && event.key === "k")
    ) {
      if (event.preventDefault) {
        event.preventDefault();
      }

      sendGAEvent({
        action: "search shortcut key used cmd+k",
        result: "search bar is now focussed",
        context: "main searchbox",
      });

      if (searchElement.current) {
        searchElement.current.focus();
      }
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  });

  const renderInputExtra = useCallback(
    ({ focused, textEntered, switchToWhiteStyles, isOpen }) => {
      let isMac;

      if (server) {
        isMac = isMacOsByUserAgent(userAgent);
      } else {
        isMac = isMacOs();
      }

      return (
        !textEntered &&
        !focused && (
          <span className={css(styles.shortcutContainer)}>
            <span
              className={css(
                styles.shortcut,
                (switchToWhiteStyles || isOpen) && styles.lightShortcut
              )}
            >
              {isMac ? "⌘" : "Ctrl"} K
            </span>
          </span>
        )
      );
    },
    [
      css,
      server,
      styles.lightShortcut,
      styles.shortcut,
      styles.shortcutContainer,
      userAgent,
    ]
  );

  const onSearchClick = useCallback(
    (event, setFocused) => {
      event.preventDefault();
      event.stopPropagation();
      const value = lastTerm || searchElement.current.value.trim();
      const linkUrl = `${searchHref}${value}`;
      if (value !== "") {
        isLoggedIn && saveRecentSearch({ search_term: value });
        setFocused(false);
        sendGAEvent({
          action: "searchTipClicked",
          search_term: value,
        });
        history.push(linkUrl);
      }
    },
    [lastTerm, searchHref, isLoggedIn, saveRecentSearch, history]
  );

  const renderSearchTip = useCallback(
    ({ focused, textEntered, switchToWhiteStyles, isOpen, setFocused }) => {
      return (
        focused && (
          <button
            onClick={(e) => onSearchClick(e, setFocused)}
            className={css(
              styles.searchTip,
              !focused &&
                textEntered &&
                !isOpen &&
                !switchToWhiteStyles &&
                styles.lightSearchTip
            )}
            data-testid="search-tip"
          >
            <span className={css(styles.searchTipInner)}>
              <FontAwesomeIcon
                data-id="search-field-icon"
                className={css(styles.icon)}
                icon={faSearch}
                fixedWidth
              />
              <span>Hit Enter</span>
            </span>
          </button>
        )
      );
    },
    [
      css,
      onSearchClick,
      styles.icon,
      styles.lightSearchTip,
      styles.searchTip,
      styles.searchTipInner,
    ]
  );

  const renderTopSearchResults = useCallback(
    ({ focused, setFocused }) => {
      return (
        focused &&
        searchTerm === "" &&
        recentSearches?.size > 0 && (
          <div className={css(styles.resultsContainer)}>
            <ul>
              {recentSearches
                ?.slice()
                .reverse()
                .slice(0, 5)
                .map((result, index) => {
                  return (
                    <TopSearchRecentSearchesItem
                      key={index}
                      result={result}
                      setFocused={setFocused}
                    />
                  );
                })}
            </ul>
          </div>
        )
      );
    },
    [styles, css, recentSearches, searchTerm]
  );

  const renderExtraIconWithSearchTip = useCallback(
    ({
      textEntered,
      switchToWhiteStyles,
      eventIsFieldTrigger,
      searchIconOnClick,
      focused,
      isOpen,
      searchIconSpin,
      setFocused,
    }) => {
      const handleClearButton = (e) => {
        searchIconOnClick && searchIconOnClick(e);
        searchElement.current.focus();
        setSearchTerm("");
      };
      return (
        <>
          {showTip &&
            textEntered &&
            renderSearchTip({
              focused,
              textEntered,
              switchToWhiteStyles,
              isOpen,
              setFocused,
            })}
          {((showSearch && mobile) || textEntered) && (
            <button
              onClick={handleClearButton}
              onKeyDown={(e) =>
                eventIsFieldTrigger(e) &&
                searchIconOnClick &&
                handleClearButton(e)
              }
              className={css(styles.clearButton)}
              data-testid="clear-button"
            >
              <FontAwesomeIcon
                data-id="search-field-icon"
                className={css(
                  styles.icon,
                  switchToWhiteStyles && styles.blackText,
                  styles.exitIcon
                )}
                icon={faTimes}
                spin={searchIconSpin}
              />
            </button>
          )}
        </>
      );
    },
    [
      css,
      mobile,
      renderSearchTip,
      showSearch,
      showTip,
      styles.blackText,
      styles.clearButton,
      styles.exitIcon,
      styles.icon,
    ]
  );

  const clearSearchResults = useClearSearchResults();

  const onKeyDown = useCallback(
    (closeMenu) => (event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        event.stopPropagation();
        const linkUrl = `${searchHref}${event?.target?.value}`;

        if (event.target.value.trim() !== "") {
          sendGAEvent({
            action: "TopSearchSubmit",
            search_term: event.target.value,
          });

          history.push(linkUrl);

          clearSearchResults();

          closeMenu();
          event.target.blur();

          isLoggedIn && saveRecentSearch({ search_term: event.target.value });
        }
      }
    },
    [searchHref, history, clearSearchResults, isLoggedIn, saveRecentSearch]
  );

  const handleFocus = useCallback(() => {
    sendGAEvent({
      action: "topSearchClick",
      context: "Search",
    });

    if (onFocus) {
      onFocus();
    }
  }, [onFocus]);

  const renderSearchField = useCallback(
    (searchProps) => (
      <SearchAutocomplete
        {...props}
        {...searchProps}
        ariaLabel={placeholder}
        className={className}
        dataId="top-level-search"
        onInputValueChange={handleSearchChange(searchProps)}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        onFocus={handleFocus}
        forwardedRef={searchElement}
        renderInputExtra={renderInputExtra}
        searchFieldStyles={finalSearchStyles}
        switchToWhiteStyles={switchToWhiteStyles}
        value={searchProps.search_term}
        topSearchTerm={searchTerm}
        styles={searchresultStyles}
        renderExtraIconWithSearchTip={renderExtraIconWithSearchTip}
        hideResults
        avoidIconEffect
        showTypeSelect={false}
        searchIconOnly
        mobileSearch={mobile && showSearch}
        renderTopSearchResults={isLoggedIn && renderTopSearchResults}
        containerRef={containerRef}
      />
    ),
    [
      props,
      placeholder,
      className,
      handleSearchChange,
      onKeyDown,
      handleFocus,
      renderInputExtra,
      finalSearchStyles,
      switchToWhiteStyles,
      searchTerm,
      renderExtraIconWithSearchTip,
      mobile,
      showSearch,
      isLoggedIn,
      renderTopSearchResults,
    ]
  );

  return (
    <SearchFieldContainer
      searchKey={searchKey}
      types={types}
      render={renderSearchField}
      onSearchChange={onSearchChange}
      onCancelSearch={onCancelSearch}
      analyticsVariables={analyticsVariables}
    />
  );
};

TopSearchContainer.propTypes = {
  placeholder: PropTypes.string,
  placeholderSmall: PropTypes.string,
  searchKey: PropTypes.string.isRequired,
  types: PropTypes.array,
  mobile: PropTypes.bool,
  onFocus: PropTypes.func,
  onSearchChange: PropTypes.func,
  onCancelSearch: PropTypes.func,
  className: PropTypes.string,
  switchToWhiteStyles: PropTypes.bool,
  showSearch: PropTypes.bool,
  showTip: PropTypes.bool,
  searchAutocompleteStyles: PropTypes.object,
};

TopSearchContainer.defaultProps = {
  placeholder: null,
  placeholderSmall: null,
  types: [],
  mobile: undefined,
  onSearchChange: null,
  onCancelSearch: null,
  className: "",
  showTip: true,
};

export default memo(TopSearchContainer);
